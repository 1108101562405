<template>
  <Dialog
    :header="$t('settings.bulkAddition')"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <FileUpload
      name="file"
      :fileLimit="1"
      :maxFileSize="$fileSize"
      :showCancelButton="false"
      :customUpload="true"
      @select="uploadFile"
      :invalidFileSizeMessage="`{0}: ${$t('global.invalidFileSize')} {1}.`"
      :invalidFileTypeMessage="`{0}: ${$t('global.invalidFileType')} {1}.`"
      :chooseLabel="$t('global.choose')"
      :uploadLabel="$t('global.upload')"
    >
      <template #empty>
        <p>{{ $t("global.dragAndDrop") }}</p>
      </template>
    </FileUpload>

    <div v-if="importedData.length > 0" class="mt-3">
      <h4 class="mb-2">{{$t('global.preview')}}</h4>
      <DataTable
        :value="importedData"
        :paginator="true"
        :rows="5"
        dataKey="id"
        showGridlines
        class="p-datatable-imported"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        :currentPageReportTemplate="$tableString"
        scrollHeight="flex"
      >
        <Column
          v-for="(col, index) in columns"
          :key="index"
          :field="col"
          :header="col"
        >
        </Column>
      </DataTable>
    </div>
    <template #footer>
      <Button
        :disabled="importedData.length == 0"
        :label="$t('global.import')"
        class="p-button-success"
        @click="importFile"
        autofocus
        :loading="loadingButton"
        iconPos="right"
      />
      <Button
        :label="$t('global.close')"
        @click="close"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
const XLSX = require("xlsx");

export default {
  name: "Bulk import",
  props: ["display", "type"],
  emits: ["closeImport", "importData"],
  data() {
    return {
      viewDialog: false,
      loadingButton: false,
      buttonElement: null,
      importedData: [],
      columns: [],
      fileObject: {},
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      this.$nextTick(() => {
        this.buttonElement = document.querySelector(
          ".p-fileupload-buttonbar button"
        );
        if (this.buttonElement) {
          this.buttonElement.style.display = "none";
        }
      });
      // console.log(this.caseValue);
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
      }
    },
  },
  methods: {
    async uploadFile(event) {
      console.log(event);
      if(event.files[0].type != "application/json") {
        const file = await event.files[0].arrayBuffer();
        const workbook = XLSX.read(file);
        this.importedData = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );
        // console.log(dataUploaded);
        this.columns = Object.keys(this.importedData[0]);
      }else {
        let reader = new FileReader();
        reader.onload = (event) => {
          this.importedData = JSON.parse(event.target.result);
          this.columns = Object.keys(this.importedData[0]);
        }
        reader.readAsText(event.files[0]);
        // this.importedData = 
      }
      this.fileObject = event.files[0];
      
    },
   async importFile() {
     this.loadingButton = true;
     let message = "";
     switch (this.type) {
       case 'country':
         await this.$store.dispatch("updateCountries", this.fileObject);
         message = this.$t("settings.updateCountries");
         break;
        case 'doy':
         await this.$store.dispatch("updateDoy", this.fileObject);
         message = this.$t("settings.updateDoy");
         break;
        case 'companyType':
         await this.$store.dispatch("updateCompanyTypes", this.fileObject);
         message = this.$t("settings.updateCompanyType");
         break;
        case 'city':
         await this.$store.dispatch("updateCities", this.fileObject);
         message = this.$t("settings.updateCity");
         break;
        case 'action':
         await this.$store.dispatch("updateActionsObject", this.fileObject);
         message = this.$t("settings.updateAction");
         break;
        case 'notification':
         await this.$store.dispatch("updateNotificationsObject", this.fileObject);
         message = this.$t("settings.updateNotification");
         break;
       default:
         break;
     }
     this.$notification(
          '',
          message
        );
     this.loadingButton = false;
      // this.$emit("importData", this.fileObject);
      this.close();
    },
    close() {
      this.$emit("closeImport");
      this.importedData = [];
      this.columns = [];
    },
  },
};
</script>
